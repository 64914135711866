import {
    useTranslation
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
    const { t } = useTranslation('static')

    const searchPlaceholder = () => {
        return t('TopPage.FirstViewSection.Search.Placeholder')
    }

    const searchShortPlaceholder = () => {
        return t('TopPage.FirstViewSection.Search.ShortPlaceholder')
    }

    return {
        searchPlaceholder,
        searchShortPlaceholder,
    }
}